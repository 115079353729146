import React, { FC, useEffect, useState } from "react";
import SmartHomeService from "../../../services/smarthome.service";
import { isLockType } from "../../../models/DeviceTypeEnum";
import {
  BackendResource,
  DeviceType,
  DeviceMake,
  DeviceModel,
  Vendor,
  Device,
  Gateway,
} from "../../../models/SmartHomeApi";

import "./AddDevice.scss";
import SpinnerOverlay from "../../../elements/Spinner/SpinnerOverlay";
import { PropertyStore } from "../../../store/property";

import SelectTypeStep from "./components/SelectTypeStep/SelectTypeStep";
import SelectMakeStep from "./components/SelectMakeStep/SelectMakeStep";
import SelectModelStep from "./components/SelectModelStep/SelectModelStep";
import InputExternalIdStep from "./components/InputExternalIdStep/InputExternalIdStep";
import SelectVendorStep from "./components/SelectVendorStep/SelectVendorStep";
import TurnKeyStepWrapper from "./components/TurnKeyStepWrapper/TurnKeyStepWrapper";
import ErrorBadge from "../../../elements/Error/ErrorBadge";

interface AddDeviceProps {
  operator?: string;
  deviceTypes?: BackendResource<DeviceType>[];
  deviceMakes?: BackendResource<DeviceMake>[];
  deviceModels?: BackendResource<DeviceModel>[];
  vendors?: BackendResource<Vendor>[];
  onSetRefreshAfterClose?: (refreshAfterClose: boolean) => void;
  onSave?: (
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway>,
  ) => void;
  onClose?: () => void;
}

export enum STEP {
  SelectDeviceType,
  SelectLockVendor,
  TurnKeySetup,
  SelectLockMake,
  SelectLockModel,
  EnterLockExternalID,
  SelectNonLockVendor,
  EnterNonLockExternalID,
}

const getPreviousStep = function (step: STEP): STEP {
  switch (step) {
    case STEP.SelectLockVendor:
    case STEP.SelectNonLockVendor:
      return STEP.SelectDeviceType;
    case STEP.TurnKeySetup:
      return STEP.SelectLockVendor;
    case STEP.SelectLockMake:
      return STEP.SelectLockVendor;
    case STEP.SelectLockModel:
      return STEP.SelectLockMake;
    case STEP.EnterLockExternalID:
      return STEP.SelectLockModel;
    case STEP.EnterNonLockExternalID:
      return STEP.SelectNonLockVendor;
    default:
      return step;
  }
};

const AddDevice: FC<AddDeviceProps> = (props) => {
  const onSetRefreshAfterClose = props.onSetRefreshAfterClose;

  const property = PropertyStore.selectors.usePropertyData()!;
  const [step, setStep] = useState(STEP.SelectDeviceType);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  // Finalized fields
  const [deviceType, setDeviceType] = useState<BackendResource<DeviceType>>();
  const [vendor, setVendor] = useState<BackendResource<Vendor>>();
  const [deviceMake, setDeviceMake] = useState<BackendResource<DeviceMake>>();
  const [deviceModel, setDeviceModel] =
    useState<BackendResource<DeviceModel>>();

  // on selecting the turnkey vendor update parent to refresh the grid when the dialog is closed
  useEffect(() => {
    const isTurnkey =
      vendor?.attributes.display_name.toLowerCase().startsWith("turnkey") ??
      false;
    onSetRefreshAfterClose?.(isTurnkey);
  }, [vendor, onSetRefreshAfterClose]);

  // set selected device type and advance to next step
  const handleType = function (type: BackendResource<DeviceType>) {
    setDeviceType(type);

    if (isLockType(type.id!)) setStep(STEP.SelectLockVendor);
    else setStep(STEP.SelectNonLockVendor);
  };

  // set selected vendor and advance to next step
  const handleVendor = function (vendor: BackendResource<Vendor>) {
    setVendor(vendor);

    let nextStep: STEP;
    if (step === STEP.SelectNonLockVendor)
      nextStep = STEP.EnterNonLockExternalID;
    else if (vendor.attributes.display_name.toLowerCase().startsWith("turnkey"))
      nextStep = STEP.TurnKeySetup;
    else nextStep = STEP.SelectLockMake;

    setStep(nextStep);
  };

  // set selected device make and advance to next step
  const handleMake = function (make: BackendResource<DeviceMake>) {
    setDeviceMake(make);
    setStep(STEP.SelectLockModel);
  };

  // set selected device model and advance to next step
  const handleModel = function (model: BackendResource<DeviceModel>) {
    setDeviceModel(model);
    setStep(STEP.EnterLockExternalID);
  };

  // create backend device and device gateway models and send to parent for saving
  const handleExternalId = function (externalId: string) {
    const active = isLockType(deviceType!.id!) ? 1 : 0;
    const newDevice: Device = {
      active,
      device_make: deviceMake?.id || 1, // default to unspecified
      device_model: deviceModel?.id || 1,
      device_type: deviceType!.id,
    };
    const newGateway: Gateway = {
      active,
      external_id: externalId,
      vendor: vendor?.id,
    };

    const service = new SmartHomeService();
    newDevice.property_id = property.id;
    newDevice.created_by = props.operator;
    newGateway.property_id = property.id;
    newGateway.operator_id = props.operator;

    setIsLoading(true);
    setError(undefined);
    service
      .createDeviceAndGateway(newDevice, newGateway)
      .then((result) => props.onSave?.(result.device, result.gateway))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleTurnKeyCompletion = function () {
    props.onClose?.();
  };

  const loadPreviousStep = function () {
    // Clear data when backing out
    switch (step) {
      case STEP.SelectLockVendor:
      case STEP.SelectNonLockVendor:
        setVendor(undefined);
        break;
      case STEP.SelectLockMake:
        setDeviceMake(undefined);
        break;
      case STEP.SelectLockModel:
        setDeviceModel(undefined);
        break;
    }
    const previous = getPreviousStep(step);
    setStep(previous);
  };

  return (
    <div className="component-add-lock-device">
      {step === STEP.SelectDeviceType && (
        <SelectTypeStep deviceTypes={props.deviceTypes} onSubmit={handleType} />
      )}
      {(step === STEP.SelectLockVendor ||
        step === STEP.SelectNonLockVendor) && (
        <SelectVendorStep
          vendors={props.vendors}
          deviceType={deviceType}
          onPrevious={loadPreviousStep}
          onSubmit={handleVendor}
        />
      )}
      {step === STEP.SelectLockMake && (
        <SelectMakeStep
          deviceMakes={props.deviceMakes}
          onPrevious={loadPreviousStep}
          onSubmit={handleMake}
        />
      )}
      {step === STEP.SelectLockModel && (
        <SelectModelStep
          deviceModels={props.deviceModels}
          onPrevious={loadPreviousStep}
          onSubmit={handleModel}
        />
      )}
      {(step === STEP.EnterLockExternalID ||
        step === STEP.EnterNonLockExternalID) && (
        <InputExternalIdStep onSubmit={handleExternalId} />
      )}
      {step === STEP.TurnKeySetup && (
        <TurnKeyStepWrapper
          property={property}
          operator={props.operator}
          onPrevious={loadPreviousStep}
          onCompletion={handleTurnKeyCompletion}
        />
      )}
      <ErrorBadge error={error} position="full-width" />
      {isLoading ? <SpinnerOverlay /> : undefined}
    </div>
  );
};

export default AddDevice;
