import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Icon } from "@vacasa/react-components-lib";
import { AgGridReact } from "ag-grid-react";

import "./HomeDeviceGrid.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import SmartHomeService from "../../services/smarthome.service";
import ButtonCellRenderer from "./CellRenderers/ButtonCellRenderer";
import {
  BackendResource,
  Device,
  Gateway,
  DeviceMake,
  DeviceModel,
  DeviceType,
  Vendor,
} from "../../models/SmartHomeApi";
import DeviceStatusRenderer from "./CellRenderers/DeviceStatusRenderer/DeviceStatusRenderer";
import DeviceTableRow from "../../models/DeviceTableRow";
import ActiveStateRenderer from "./CellRenderers/ActiveStateRenderer/ActiveStateRenderer";
import DeviceTypeRenderer from "./CellRenderers/DeviceTypeRenderer";
import RemoveDeviceDialog from "./RemoveDeviceDialog/RemoveDeviceDialog";
import EditDeviceDialog from "./EditDeviceDialog/EditDeviceDialog";
import AddButton from "../../elements/Button/AddButton";
import DataPlaceholder from "../../elements/Templates/DataPlaceholder/DataPlaceholder";
import AnchoredDialog, {
  AnchorPoint,
} from "../../elements/Dialog/AnchoredDialog";
import AddDevice from "./AddDevice/AddDevice";
import useWindowDimensions from "../../hooks/Window";
import { MOBILE_SCREEN_MAX_WIDTH } from "../../core/constants";
import MenuCellRenderer from "./CellRenderers/MenuCellRenderer/MenuCellRenderer";
import DeviceCompatibility from "../../elements/Templates/DeviceCompatibility/DeviceCompatibility";
import { useLockCompatibility } from "../../store/device-compatibility/selector";
import { PropertyStore } from "../../store/property";
import UnauthorizedMessage from "../../elements/Templates/UnauthorizedMessage/UnauthorizedMessage";
import DeviceDetailsDialog from "./DeviceDetailsDialog/DeviceDetailsDialog";
import { uniqueId } from "lodash";
import { HttpResponseError } from "../../core/errors";

interface HomeDeviceGridProps {}

interface HomeDeviceData {
  deviceMakes?: BackendResource<DeviceMake>[];
  deviceModels?: BackendResource<DeviceModel>[];
  deviceTypes?: BackendResource<DeviceType>[];
  deviceGateways?: BackendResource<Gateway>[];
  devices?: BackendResource<Device>[];
  vendors?: BackendResource<Vendor>[];
}

const HomeDeviceGrid: FC<HomeDeviceGridProps> = () => {
  const [data, setData] = useState<HomeDeviceData>();
  const [dataHash, setDataHash] = useState(uniqueId("data-key-"));
  const [refreshDataAt, setRefreshDataAt] = useState(new Date());

  const canAddLock = useLockCompatibility();

  const [isAddingDevice, setIsAddingDevice] = useState(false);
  const [addDeviceOffset, setAddDeviceOffset] = useState<[number, number]>([
    0, 0,
  ]);
  const [addDeviceAnchor, setAddDeviceAnchor] = useState<AnchorPoint>("CENTER");

  const [editingRow, setEditingRow] = useState<DeviceTableRow>();
  const [rowDetails, setRowDetails] = useState<DeviceTableRow>();
  const [removingRow, setRemovingRow] = useState<DeviceTableRow>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [refreshAfterClose, setRefreshAfterClose] = useState(false);

  const [searchParams] = useSearchParams();
  const [operatorId, setOperatorId] = useState<string>();

  const property = PropertyStore.selectors.usePropertyData()!;
  const propertyId = property.id;

  const window = useWindowDimensions();
  const gridRef = useRef<any>();
  const [gridReadyAt, setGridReadyAt] = useState(new Date());

  const [isAuthorized, setIsAuthorized] = useState(true);

  // Loads all needed data for table view
  useEffect(() => {
    const smartHomeService = new SmartHomeService();

    const dataRequests = [
      { id: "deviceMakes", request: () => smartHomeService.getDeviceMakes() },
      { id: "deviceModels", request: () => smartHomeService.getDeviceModels() },
      { id: "deviceTypes", request: () => smartHomeService.getDeviceTypes() },
      { id: "vendors", request: () => smartHomeService.getVendors() },
      {
        id: "deviceGateways",
        request: () => smartHomeService.getGateways(propertyId),
      },
      { id: "devices", request: () => smartHomeService.getDevices(propertyId) },
    ];

    // make the api calls
    const promises = dataRequests.map((obj) => obj.request());

    // function to sort device make, model, type, and vendors alphabetically
    const sortObjects = (a: BackendResource<any>, b: BackendResource<any>) => {
      if (a.type === "devices" || a.type === "device_gateways") return 0;

      const aValue = (a.attributes.display_name ?? a.attributes.name) as string;
      const bValue = (b.attributes.display_name ?? b.attributes.name) as string;

      return aValue.localeCompare(bValue);
    };

    // wait for all api calls to finish, then aggregate results
    Promise.all(promises)
      .then((responses) => {
        const result: HomeDeviceData = {
          deviceMakes: [],
          deviceModels: [],
          deviceTypes: [],
          deviceGateways: [],
          devices: [],
        };
        responses.forEach((res, index) => {
          (result as any)[dataRequests[index].id] = res!.data.sort(sortObjects);
        });
        updateDataState(result);
      })
      .catch((err) => {
        updateDataState({
          deviceMakes: [],
          deviceModels: [],
          deviceTypes: [],
          deviceGateways: [],
          devices: [],
        });
        setErrorMessage(err.message);
        if (err instanceof HttpResponseError && err.status === 403) {
          setIsAuthorized(false);
        }
      });
  }, [propertyId, refreshDataAt]);

  // Parse backend data to display data
  const tableRows = useMemo<DeviceTableRow[] | undefined>(() => {
    let tableData: DeviceTableRow[] | undefined;
    if (data?.devices) {
      tableData = [];
      data.devices.forEach((device) => {
        const gateway = data.deviceGateways?.find(
          (x) => x.id === device.attributes.device_gateway,
        );

        // Contents of a single row
        const rowData = DeviceTableRow.fromBackendData(device, gateway);
        rowData.setTypeText(data?.deviceTypes);
        rowData.setMakeText(data?.deviceMakes);
        rowData.setModelText(data?.deviceModels);
        rowData.setVendorText(data?.vendors);

        tableData!.push(rowData);
      });
    }
    return tableData?.sort(DeviceTableRow.compare);
  }, [data]);

  // parse query params
  useEffect(() => {
    const operator = searchParams.get("operator");
    setOperatorId(operator ?? undefined);
  }, [searchParams]);

  // Adjust grid size based on window size
  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
    gridRef?.current?.api?.setDomLayout?.("autoHeight");
  }, [window, gridReadyAt]);

  // Display/hide add device dialog
  const showAddDeviceModal = function (e: MouseEvent, source: number) {
    setRefreshAfterClose(false);
    setIsAddingDevice(true);
    if (source === 0) {
      setAddDeviceAnchor("TOP");
      setAddDeviceOffset([381, 29]);
    } else {
      setAddDeviceAnchor("CENTER");
      setAddDeviceOffset([0, 0]);
    }
  };

  const closeAddDeviceModal = function () {
    setIsAddingDevice(false);
    if (refreshAfterClose) {
      setRefreshDataAt(new Date());
    }
  };

  // Display/hide edit device dialog
  const showEditDeviceModal = useCallback((tableRow: DeviceTableRow) => {
    setEditingRow(tableRow);
  }, []);

  const hideEditDeviceModal = function () {
    setEditingRow(undefined);
  };

  // Display/hide device details dialog
  const showDeviceDetailsModal = useCallback((tableRow: DeviceTableRow) => {
    setRowDetails(tableRow);
  }, []);

  const hideDeviceDetailsModal = function () {
    setRowDetails(undefined);
  };

  // Display/hide remove device dialog
  const showRemoveDeviceModal = useCallback((row: DeviceTableRow) => {
    setRemovingRow(row);
  }, []);

  const hideRemoveDeviceModal = function () {
    setRemovingRow(undefined);
  };

  const updateDataState = (data: any) => {
    setData(data);
    setDataHash(uniqueId("data-key-"));
  };

  // Adds new device & gateway to data
  const handleDeviceCreated = function (
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway>,
  ) {
    const dataCopy = { ...data };
    dataCopy.devices!.push(device);
    dataCopy.deviceGateways!.push(gateway);

    updateDataState(dataCopy);
    closeAddDeviceModal();
  };

  // Replaces a device & gateway in data
  const handleDeviceModified = function (
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway>,
  ) {
    const dataCopy = { ...data };
    const deviceIndex = dataCopy.devices!.findIndex((x) => x.id === device.id);
    const gatewayIndex = dataCopy.deviceGateways!.findIndex(
      (x) => x.id === gateway.id,
    );

    if (deviceIndex >= 0) dataCopy.devices![deviceIndex] = device;
    if (gatewayIndex >= 0) dataCopy.deviceGateways![gatewayIndex] = gateway;

    updateDataState(dataCopy);
  };

  // Removes a device & gateway from data
  const handleDeviceRemoved = function (deviceId: number, gatewayId: number) {
    const dataCopy = { ...data };
    const deviceIndex = dataCopy.devices!.findIndex((x) => x.id === deviceId);
    const gatewayIndex = dataCopy.deviceGateways!.findIndex(
      (x) => x.id === gatewayId,
    );

    if (deviceIndex >= 0) dataCopy.devices!.splice(deviceIndex, 1);
    if (gatewayIndex >= 0) dataCopy.deviceGateways!.splice(gatewayIndex, 1);

    updateDataState(dataCopy);
    hideRemoveDeviceModal();
  };

  const handleSetRefreshAfterClose = function (refresh: boolean) {
    setRefreshAfterClose(refresh);
  };

  // AG Grid settings
  const colDefaults = useMemo(() => {
    if (window.width <= MOBILE_SCREEN_MAX_WIDTH) {
      return {
        sortable: false,
        unSortIcon: false,
        suppressMovable: true,
      };
    } else {
      return {
        sortable: true,
        //sortingOrder: ["asc", "desc", null],
        unSortIcon: true,
      };
    }
  }, [window]);

  // AG Grid columns
  const columnsToDisplay = useMemo(() => {
    const detailColumn = {
      width: 5,
      sortable: false,
      cellRenderer: ButtonCellRenderer,
      cellRendererParams: {
        icon: <Icon.Plus width={18} height={18} style={{ top: 5 }} />,
        onClick: showDeviceDetailsModal,
      },
    };
    const activeColumn = {
      headerName: "ACTIVE",
      field: "active",
      cellRenderer: ActiveStateRenderer,
    };
    const typeColumn = {
      headerName: "TYPE",
      field: "type",
      cellRenderer: DeviceTypeRenderer,
    };
    const makeColumn = { headerName: "MAKE", field: "make" };
    const modelColumn = { headerName: "MODEL", field: "model" };
    const vendorColumn = { headerName: "VENDOR", field: "vendor" };
    const externalIdColumn = { headerName: "EXTERNAL ID", field: "externalId" };
    const statusColumn = {
      headerName: "STATUS",
      field: "status",
      cellRenderer: DeviceStatusRenderer,
      cellStyle: { overflow: "visible" },
    };

    const editColumn = {
      width: 30,
      sortable: false,
      cellRenderer: ButtonCellRenderer,
      cellRendererParams: {
        icon: <Icon.Edit width={18} height={18} style={{ top: 5 }} />,
        onClick: showEditDeviceModal,
      },
    };
    const deleteColumn = {
      width: 30,
      sortable: false,
      cellRenderer: ButtonCellRenderer,
      cellRendererParams: {
        icon: <Icon.Trash width={18} height={18} />,
        onClick: showRemoveDeviceModal,
      },
    };
    const mobileMenuColumn = {
      width: 30,
      sortable: false,
      cellRenderer: MenuCellRenderer,
      cellRendererParams: {
        menuItems: [
          { displayText: "Edit", callback: showEditDeviceModal },
          { displayText: "Remove", callback: showRemoveDeviceModal },
        ],
      },
      cellStyle: { overflow: "visible" },
    };

    if (window.width <= MOBILE_SCREEN_MAX_WIDTH) {
      // return less columns for mobile display
      return [
        typeColumn,
        vendorColumn,
        externalIdColumn,
        statusColumn,
        mobileMenuColumn,
      ];
    } else {
      // return all columns
      return [
        detailColumn,
        activeColumn,
        typeColumn,
        makeColumn,
        modelColumn,
        vendorColumn,
        externalIdColumn,
        statusColumn,
        editColumn,
        deleteColumn,
      ];
    }
  }, [
    showRemoveDeviceModal,
    showEditDeviceModal,
    showDeviceDetailsModal,
    window,
  ]);

  const onGridReady = () => {
    setGridReadyAt(new Date());
  };

  if (!isAuthorized) return <UnauthorizedMessage tabName="Home Access" />;
  return (
    <div data-testid="HomeDeviceGrid">
      <div className="add-device-row">
        <div className="action">
          <AddButton
            id="btn-add-device"
            display="alt"
            onClick={(e) => showAddDeviceModal(e, 0)}
            hidden={isAddingDevice || tableRows?.length === 0 || !canAddLock}
          >
            Add a Device
          </AddButton>
        </div>
      </div>
      {tableRows?.length === 0 ? (
        <>
          <DeviceCompatibility locks={[]} />
          <DataPlaceholder
            title="No Smart Devices Added Yet"
            message="Tap the “add a Device” button below to add the first Smart Home device to this unit"
            btnText="Add a Device"
            btnDisabled={!canAddLock}
            btnHoverText={
              !canAddLock
                ? "In order to add a device, you'll need to change compatible with unit to Yes."
                : undefined
            }
            onBtnClick={(e) => showAddDeviceModal(e, 1)}
            errorMessage={errorMessage}
          />
        </>
      ) : (
        <>
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                rowHeight={70}
                rowData={tableRows}
                columnDefs={columnsToDisplay}
                defaultColDef={colDefaults}
                onGridReady={onGridReady}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                ref={gridRef}
              ></AgGridReact>
            </div>
          </div>
          <DeviceCompatibility key={dataHash} locks={data?.devices} />
        </>
      )}
      {isAddingDevice && (
        <AnchoredDialog
          anchor={addDeviceAnchor}
          offset={addDeviceOffset}
          onClose={closeAddDeviceModal}
        >
          <AddDevice
            operator={operatorId}
            deviceTypes={data?.deviceTypes}
            deviceMakes={data?.deviceMakes}
            deviceModels={data?.deviceModels}
            vendors={data?.vendors}
            onSave={handleDeviceCreated}
            onSetRefreshAfterClose={handleSetRefreshAfterClose}
            onClose={closeAddDeviceModal}
          />
        </AnchoredDialog>
      )}
      {editingRow && (
        <AnchoredDialog onClose={hideEditDeviceModal}>
          <EditDeviceDialog
            operator={operatorId}
            dataRow={editingRow}
            deviceTypes={data?.deviceTypes}
            deviceModels={data?.deviceModels}
            deviceMakes={data?.deviceMakes}
            vendors={data?.vendors}
            onSave={handleDeviceModified}
            onClose={hideEditDeviceModal}
          />
        </AnchoredDialog>
      )}
      {rowDetails && (
        <AnchoredDialog onClose={hideDeviceDetailsModal}>
          <DeviceDetailsDialog dataRow={rowDetails} />
        </AnchoredDialog>
      )}
      {removingRow && (
        <AnchoredDialog onClose={hideRemoveDeviceModal}>
          <RemoveDeviceDialog
            dataRow={removingRow}
            tableData={tableRows}
            onClose={hideRemoveDeviceModal}
            onSave={handleDeviceRemoved}
            operator={operatorId}
          />
        </AnchoredDialog>
      )}
    </div>
  );
};

export default HomeDeviceGrid;
