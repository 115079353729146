import React, { FC, useEffect, useState } from "react";
import { Image } from "@vacasa/react-components-lib";
import { useEscapeKeyListener } from "../../../hooks/KeyListeners";
import DeviceTableRow from "../../../models/DeviceTableRow";
import {
  BackendResource,
  Device,
  DeviceMake,
  DeviceModel,
  DeviceType,
  Gateway,
  Vendor,
} from "../../../models/SmartHomeApi";
import { isLockType } from "../../../models/DeviceTypeEnum";
import SmartHomeService from "../../../services/smarthome.service";

import styles from "./EditDeviceDialog.module.scss";
import BasicButton from "../../../elements/Button/BasicButton";
import { AxiosError } from "axios";

interface EditDeviceDialogProps {
  operator?: string;
  dataRow: DeviceTableRow;
  deviceTypes?: BackendResource<DeviceType>[];
  deviceMakes?: BackendResource<DeviceMake>[];
  deviceModels?: BackendResource<DeviceModel>[];
  vendors?: BackendResource<Vendor>[];
  onClose?: () => void;
  onSave?: (
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway>,
  ) => void;
}

const EditDeviceDialog: FC<EditDeviceDialogProps> = (props) => {
  const { deviceMakes, deviceModels } = props;
  const handleClose = function () {
    props.onClose?.();
  };
  useEscapeKeyListener(handleClose);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isActive, setIsActive] = useState<boolean>(props.dataRow.active);

  const [deviceType, setDeviceType] = useState<number>(props.dataRow.typeId);
  const [make, setMake] = useState<number>(props.dataRow.makeId);
  const [model, setModel] = useState<number>(props.dataRow.modelId);

  const [allowDeviceDetail, setAllowDeviceDetail] = useState<boolean>();

  const [isFormValid, setIsFormValid] = useState(false);

  const handleActiveChange = function (event: any) {
    setIsActive(event.target.checked);
  };
  const handleTypeChange = function (event: any) {
    setDeviceType(Number(event.target.value));
  };
  const handleMakeChange = function (event: any) {
    setMake(Number(event.target.value));
  };
  const handleModelChange = function (event: any) {
    setModel(Number(event.target.value));
  };

  const handleSubmit = function (event: MouseEvent) {
    event.preventDefault();
    if (isFormValid) {
      const smartHomeService = new SmartHomeService();

      const device = props.dataRow.cloneDevice();
      const gateway = props.dataRow.cloneGateway();

      device.attributes.updated_by = props.operator;
      gateway.attributes.updated_by = props.operator;
      gateway.attributes.operator_id = props.operator;

      device.attributes.active = isActive ? 1 : 0;
      gateway.attributes.active = isActive ? 1 : 0;

      device.attributes.device_type = deviceType;
      device.attributes.device_make = make;
      device.attributes.device_model = model;

      gateway.attributes.vendor = props.dataRow.vendorId;
      gateway.attributes.external_id = props.dataRow.externalId!;

      setIsLoading(true);
      smartHomeService
        .updateDeviceAndGateway(device, gateway)
        .then((res) => {
          props.onSave?.(res.device, res.gateway);
          handleClose();
        })
        .catch((err) => {
          let msg: string = err.message;
          if (err instanceof AxiosError && err.response?.status === 400) {
            if (err.response.data?.errors) {
              const firstErr = err.response.data.errors[0];
              if (typeof firstErr?.detail === "string") msg = firstErr.detail;
            }
          }
          setErrorMessage(msg);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Enables/disables the make & model based off the device type
  useEffect(() => {
    if (deviceType !== undefined) {
      const isLock = isLockType(deviceType);
      if (!isLock) {
        let makeNotSpecified = 1;
        let modelNotSpecified = 1;
        const isNotSpecified = (x: any) => {
          return x.attributes.name.toLowerCase() === "not specified";
        };

        const make = deviceMakes?.find(isNotSpecified);
        if (make) makeNotSpecified = make.id as number;

        const model = deviceModels?.find(isNotSpecified);
        if (model) modelNotSpecified = model.id as number;

        setMake(makeNotSpecified);
        setModel(modelNotSpecified);
      }
      setAllowDeviceDetail(isLock);
    }
  }, [deviceType, deviceMakes, deviceModels]);

  // Checks whether the entire form is valid
  useEffect(() => {
    const checks = [
      deviceType !== undefined,
      make !== undefined,
      model !== undefined,
    ];
    setIsFormValid(checks.every((check) => check));
  }, [deviceType, make, model]);

  return (
    <div className={styles.EditDeviceDialog} data-testid="EditDeviceDialog">
      <form className="edit-form">
        <div className="row">
          <div className="active">
            <input
              type="checkbox"
              checked={isActive}
              onChange={handleActiveChange}
            />
            <label>Active</label>
          </div>
        </div>
        <div className="row">
          <div className="col device-type">
            <div className="label">Device Type</div>
            <select onChange={handleTypeChange} value={deviceType}>
              {props.deviceTypes?.map((dType) => (
                <option key={dType.id} value={dType.id}>
                  {dType.attributes.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col device-make">
            <div className="label">Make</div>
            <select
              onChange={handleMakeChange}
              value={make}
              disabled={allowDeviceDetail ? undefined : true}
            >
              {props.deviceMakes?.map((make) => (
                <option key={make.id} value={make.id}>
                  {make.attributes.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col device-model">
            <div className="label">Model</div>
            <select
              onChange={handleModelChange}
              value={model}
              disabled={allowDeviceDetail ? undefined : true}
            >
              {props.deviceModels?.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.attributes.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row actions">
          <div className="cancel">
            <button className="btn-alt" onClick={handleClose}>
              Cancel
            </button>
          </div>
          {isLoading ? (
            <div className="spinner">
              <Image.Spinner width={36} height={36} />
            </div>
          ) : (
            <div className="submit">
              <BasicButton
                display="fill"
                disabled={!isFormValid}
                onClick={handleSubmit}
              >
                Save
              </BasicButton>
            </div>
          )}
        </div>
      </form>
      <div className={styles.ErrorMessage}>{errorMessage}</div>
    </div>
  );
};

export default EditDeviceDialog;
